<template>
  <div v-if="flag" class="margins2">
    <!-- {{ dateComment + " " + label }} -->
    <br v-if="labelPosition != 'left'" />
    <template v-for="(el, i) in peopleBirthList">
      {{ "\u{1F382}" }} {{ i > 0 ? ", " : "" + el.nickname + "," }}&nbsp;
    </template>
    {{ label }}
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";

export default {
  name: "BirthDay",
  props: {
    date: Date,
    dateComment: String,
    label: String,
    labelPosition: String,
  },

  data: function () {
    return { peopleBirthList: Array, flag: Number };
  },

  async created() {
    this.flag = 0;
    this.peopleBirthList = await request("/api/user/listbirth");
    if (this.peopleBirthList) {
      this.peopleBirthList = this.peopleBirthList.filter(
        (i) =>
          new Date(i.birth).getMonth() == new Date(this.date).getMonth() &&
          new Date(i.birth).getDate() == new Date(this.date).getDate()
      );
      this.flag = this.peopleBirthList.length;
    }
  },
};
</script>
